@import "static/styles/colors";
@import "static/styles/constants";
@import "static/styles/functions";

.manifesto {
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 684px;

	.title {
		font-size: $font-size-xxxl;
		margin: 128px $spacing-md $spacing-xxxl $spacing-md;

		@media (max-width: $max-width-md) {
			font-size: $font-size-xl;
			margin: $spacing-xxxl $spacing-md $spacing-xxl $spacing-md;
		}
	}

	.content {
		margin: $spacing-xxxl $spacing-md;

		h2 {
			margin-bottom: $spacing-md;
		}

		p {
			color: $color-interface-dark-deep;
			font-size: 1.125rem;
			font-weight: $font-weight-medium;
			line-height: $line-height-xs;
			margin-bottom: $spacing-md;
		}
	}
}
